import { DeepPartial } from 'redux';
import { LevelUpService } from 'services';
import { DeepRawify } from 'types';
import { v4 } from 'uuid'
import moment, { Moment } from 'moment';
import { B64File } from 'classes/B64File.class';
import { FichePhoto } from './FichePhoto.class';
import { Note } from 'features/level_up/src/fiches/store/types';
import { Notifiable } from 'classes/notifications/Notifiable.class';

/**
 * Fiche enregistrée dans Level Up
 */
export class Fiche extends Notifiable
{
    public getUrl(): string {
        return "/level-up/fiches/categories/" + this.idCategorie + "/" + this.idFiche + "/";
    }
    public getID(): number {
        return this.idFiche;
    }
    public notifType = 14;

    /**
     * L'identifiant de la fiche
     * @var int
     */
    public idFiche: number;

    /**
     * Date de début d'affichage
     * @var int
     */
    public dateAffichage: Moment;

    /**
     * Le libellé de la fiche
     * @var string
     */
    public libelle: string;

    /**
     * Identifiant Basic
     * @var int
     */
    public idApi: number;

    /**
     * Identifiant de la marque associée
     * @var int
     */
    public idMarque: number;

    /**
     * Identifiant de la famille associée
     * @var int
     */
    public idFamille: number;

    /**
     * Identifiant de la catégprie associée
     * @var int
     */
    public idCategorie: number;

    /**
     * Nombre de commentaires pour affichage front
     * @var int
     */
    public nbCommentaires: number;

    /**
     * L'article est-il en promotion ?
     * @var bool
     */
    public promo: boolean;

    /**
     * Statue d'activation de la fiche
     * @var bool
     */
    public enLigne: boolean;

    /**
     * Lien de la première vidéo
     * @var string
     */
    public lienVideo1: string;

    /**
     * Lien de la seconde vidéo
     * @var string
     */
    public lienVideo2: string;

    /**
     * Lien de la troisième vidéo
     * @var string
     */
    public lienVideo3: string;

    /**
     * Lien du document associé
     * @var string
     */
    public lienDocument: B64File;

    /**
     * Avantage
     * @var string
     */
    public avantage: string;

    /**
     * Date de la création de la fiche
     * @var int
     */
    public dateCreation: Moment;

    /**
     * Date de la modification de la fiche
     * @var int
     */
    public dateMiseAJour: Moment;

    /**
     * Liste des canaux de la fiche (champ spécial)
     * @var array
     */
    public canaux: number[];

    /**
     * Liste des photos de la fiche (champ spécial)
     * @var array
     */
    public photos: FichePhoto[];

    /**
     * Notes de la fiche
     * @var array
     */
    public notes: Note[];

    /**
     * Le quiz est il disponible
     * @var boolean
     */
    public quizDispo: boolean;

    /**
     * Le quiz a-t-il été fait
     * @var boolean
     */
    public quizFait: boolean;

    /**
     * Champ spécial pour spécifier l'affichage : est-ce une promo ou un odr
     */
    public promoOdrLabel: string;

    private levelUpService = LevelUpService.getInstance();


    /**
     * 
     *  Constructeur de la fiche
     * @param  mixed row
     * @return void
     */
    constructor(row?: DeepPartial<DeepRawify<Fiche>>) {
        super();
        if (row) {
            this.idFiche = row['idFiche'] ?? null;
            this.dateAffichage = row['dateAffichage'] ? moment(row['dateAffichage'], 'X') : moment();
            this.libelle = row['libelle'] ?? null;
            this.idApi = row['idApi'] ?? null;
            this.idCategorie = row['idCategorie'] ?? null;
            this.idMarque = row['idMarque'] ?? null;
            this.idFamille = row['idFamille'] ?? null;
            this.promo = row['promo'] ? true : false;
            this.promoOdrLabel = row['promoOdrLabel'] ?? "";
            this.enLigne = row['enLigne'] ? true : false;
            this.lienVideo1 = row['lienVideo1'] ?? null;
            this.lienVideo2 = row['lienVideo2'] ?? null;
            this.lienVideo3 = row['lienVideo3'] ?? null;
            this.dateCreation = row['dateCreation'] ? moment(row['dateCreation'], 'X') : moment();
            this.lienDocument =  B64File.fromDb(row['lienDocument']);
            this.avantage = row['avantage'] ?? null;
            this.dateMiseAJour = row['dateMiseAJour'] ? moment(row['dateMiseAJour'], 'X') : moment();
            this.canaux = row['canaux'] ?? [];
            this.notes = row['notes'] ?? [];
            this.nbCommentaires = row['nbCommentaires'] ?? 0;
            this.quizDispo = row['quizDispo'] ? true : false;
            this.quizFait = row['quizFait'] ? true : false;

            // Le back renvoie la liste des photos dans un array
            this.photos = row['photos'].map((i) => new FichePhoto(i)) ?? [];
        } else {
            // Initialisation de certains champs automatique
            this.canaux = [];
            this.photos = [];
            this.dateAffichage = moment();
        }
    }

    public save(): Promise<boolean> {
        return this.levelUpService.saveFiche(this);
    }

    public delete: () => Promise<boolean> = () => {
        return this.levelUpService.deleteFiche(this.idFiche);
    }

    public import: () => Promise<Fiche> = () => {
        return this.levelUpService.importFiche(this);
    }

    public toRaw() {
        return {
            idFiche: this.idFiche,
            dateAffichage: this.dateAffichage && this.dateAffichage.unix(),
            libelle: this.libelle,
            idApi: this.idApi,
            idCategorie: this.idCategorie,
            promo: this.promo,
            idMarque: this.idMarque,
            idFamille: this.idFamille,
            enLigne: this.enLigne,
            lienVideo1: this.lienVideo1,
            lienVideo2: this.lienVideo2,
            lienVideo3: this.lienVideo3,
            dateCreation: this.dateCreation && this.dateCreation.unix(),
            dateMiseAJour: this.dateMiseAJour && this.dateMiseAJour.unix(),
            lienDocument: this.lienDocument,
            avantage: this.avantage,
            canaux: this.canaux,
            photos: this.photos.map((i) => i.toRaw())
        };
    }

}
