import React from 'react';

interface MomindumProps {
    /** URL de la vidéo */
    url: string;
}

const Momindum = (props: MomindumProps) => {
    return (
        <iframe 
            src={props.url + "?displayHeader=false&autoplay=false"} 
            allowFullScreen 
            style={{ width: '100%', aspectRatio: "16/9", border: 'none' }}></iframe>   
    );
}

Momindum.canPlay = (url: string) => {
    return url.match(/(?:api\.momindum\.com\/)/)
};

export default Momindum;