import { combineEpics } from "redux-observable";
import CanauxEpics from "../canaux/src/store/epics";



const ParametresEpics = combineEpics(
    CanauxEpics
);

export default ParametresEpics;
