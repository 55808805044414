import { createStyles, makeStyles } from '@material-ui/styles';
import { colors, Theme } from './Theme';

export default makeStyles((theme: typeof Theme) => createStyles({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: 250,
    },
    toolbar: {
        display: 'flex',
        flexDirection : 'column',
        backgroundColor : 'black',
        padding : theme.spacing(2),
        paddingBottom : theme.spacing(1),
        paddingTop: 'env(safe-area-inset-top, 20px)'
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    containerListPosts: {
        width: '100%',
        marginRight: 'auto',
        marginLeft: 'auto',
        transition: '0.5s',
        padding: theme.spacing(1),

        '@media (min-width: 576px)': {
            maxWidth: 540
        },
        '@media (min-width: 768px)': {
            maxWidth: 720
        },
        '@media (min-width: 992px)': {
            maxWidth: 960
        },
        '@media (min-width: 1200px)': {
            maxWidth: 1140
        }
    }
}));