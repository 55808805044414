import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Icon from '@material-ui/core/Icon';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CheckCircleSVG from '@material-ui/icons/CheckCircle';
import ErrorSVG from '@material-ui/icons/Error';
import React, { Component } from "react";
import { colors, Theme } from "Theme";

var providers = [
    {
        service: "youtube",
        regex: /(?:youtube\.(com|fr)|youtu\.be)(?:\/watch\?v=|\/)(.+)/,
        iframe: '<iframe class="resp-iframe" src="https://www.youtube.com/embed/{id}?rel=0" frameborder="0" allowfullscreen > </iframe>   '
    },
    {
        service: "vimeo",
        regex: /(?:vimeo\.com\/)(?:channels\/[A-z]+\/)?([0-9]+)/,
        iframe:
            '<iframe src="https://player.vimeo.com/video/{id}?color=ffffff&title=0&byline=0&portrait=0" class="resp-iframe" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>'
    },
    {
        service: "dailymotion",
        regex: /(?:dailymotion\.com\/|dai\.ly)(?:video|hub)?\/([0-9a-z]+)/,
        iframe: '<iframe frameborder="0" class="resp-iframe" src="//www.dailymotion.com/embed/video/{id}" allowfullscreen></iframe>'
    },
    {
        service: "momindum",
        regex: /(?:api\.momindum\.com\/)/,
        iframe: ''
    },

    /*
    ,{
        service: 'image',
        regex: /^((?:https?:\/\/)?.+jpg|png|gif)$/,
        iframe: '<img src="{id}" style="display: block;" />'
    }
    */
];

var urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}(:[0-9]{1,5})?(\/.*)?$/;

interface AddVideoLinkProps {
    triggered: boolean;
    onClose: ((linkVideo: string) => void);
    video: boolean;
}

interface AddVideoLinkState {
    open: boolean;
    validLink: boolean;
    link: string;
}

class AddVideoLinkDialogComponent extends Component<AddVideoLinkProps, AddVideoLinkState> {

    public readonly state: AddVideoLinkState = {
        open: false,
        validLink: false,
        link: "",
    };

    public componentDidUpdate(prevProps: AddVideoLinkProps): void {
        if (prevProps.triggered !== this.props.triggered && this.props.triggered && !this.state.open) {
            this.setState({ open: true });
        }
    }

    handleChangeLink(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            link: event.target.value,
            validLink: this.is_url_video(event.target.value, this.props.video)
        })
    }

    is_url_video(str: string, video: boolean) {
        var bIsUrlVideo;

        var matches = [],
            id,
            service,
            iframe,
            regex;

        bIsUrlVideo = false;

        if (video) {
            for (let i = 0; i < providers.length; i++) {
                regex = new RegExp(providers[i].regex);
                if ((matches = str.match(regex))) {
                    id = matches[1];
                    bIsUrlVideo = true;
                }
            }
        } else {
            regex = new RegExp(urlRegex);
            if ((matches = str.match(regex))) {
                id = matches[1];
                bIsUrlVideo = true;
            }
        }

        return bIsUrlVideo;
    }

    public closeDialog(ok: boolean) {
        ok ? this.props.onClose(this.state.link) : this.props.onClose("");

        this.setState({
            open: false,
            validLink: false,
            link: ""
        });
    }

    public render(): JSX.Element {
        const { video } = this.props;
        return (
            <Dialog fullWidth open={this.state.open} onClose={() => this.closeDialog(false)}>
                <DialogTitle><p style={{ color: 'black', fontWeight: 700 }}>Ajouter {video ? 'une vidéo' : 'un lien URL'}</p></DialogTitle>

                <DialogContent>
                    <Typography style={{marginBottom : Theme.spacing(1)}}>Entrez l'URL {video && 'de la vidéo '}dans le bloc ci-dessous</Typography>
                    <TextField InputProps={{
                        type: "url",
                        endAdornment: (
                            <InputAdornment position="end">
                                <Icon color='primary'>
                                    {
                                        this.state.validLink ?
                                            <CheckCircleSVG style={{ color : colors.green.main }} />
                                            :
                                            <ErrorSVG style={{ color : colors.yellow.main }}/>
                                    }
                                </Icon>
                            </InputAdornment>
                        )
                    }} autoFocus fullWidth value={this.state.link || ""} onChange={this.handleChangeLink.bind(this)} placeholder={`Lien ${video ? 'vidéo' : ''}`} />
                    {/* { this.state.validLink &&
                    <CheckCircleSVG style={{color: "#50BE87"}}/>
                } */}
                </DialogContent>

                <DialogActions>
                    <Button size="large" variant="contained" color="secondary" onClick={() => this.closeDialog(false)}>
                        Annuler
                </Button>
                    <Button size="large" variant="contained" color="primary" disabled={!this.state.validLink} onClick={this.closeDialog.bind(this, true)}>
                        Ajouter
                </Button>
                </DialogActions>
            </Dialog>);
    }

}

export default AddVideoLinkDialogComponent;